<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        ref="refForm"
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetLocalForm"
      >
        <b-tabs
          v-model="tabAdd"
          pills
          nav-wrapper-class="col-12"
          content-class="col-12 col-md-12 mt-1 mt-md-0"
          :disabled="false"
        >
          <!-- general tab -->
          <b-tab active>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('General') }}</span>
            </template>

            <b-card class="p-0">
              <!-- media -->
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      height="80"
                    />
                  </b-link>
                <!--/ avatar -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- button -->
                  <b-button
                    ref="refSelectImage"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    {{ $t('Select Image') }}
                  </b-button>
                  <b-form-file
                    ref="refInputEl"
                    v-model="imageFile"
                    accept=".jpg, .png, .gif"
                    :hidden="true"
                    plain
                    @input="inputImageRenderer"
                  />
                  <!--/ upload button -->

                  <!-- reset -->
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="resetImage"
                  >
                    {{ $t('Reset Image') }}
                  </b-button>
                  <!--/ reset -->
                  <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                </b-media-body>
              </b-media>
              <!--/ media -->

              <validation-observer
                ref="refGeneralObserver"
              >
                <user-add-general
                  v-if="localUser.general"
                  :general-data="localUser.general"
                />
              </validation-observer>
            </b-card>
          </b-tab>
          <!--/ general tab -->

          <!-- permission tab -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="KeyIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('Permission') }}</span>
            </template>

            <validation-observer
              ref="refPermissionObserver"
            >
              <user-add-permission
                v-if="localUser.permission"
                :permission-data="localUser.permission"
              />
            </validation-observer>
          </b-tab>
          <!--/ permission tab -->

          <!-- setting password tab -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="LockIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('Setting Password') }}</span>
            </template>

            <validation-observer
              ref="refPasswordObserver"
            >
              <user-add-password
                v-if="localUser.password"
                :password-data="localUser.password"
              />
            </validation-observer>
          </b-tab>
          <!--/ setting password tab -->

          <!-- info -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('Information') }}</span>
            </template>

            <validation-observer
              ref="refInformationObserver"
            >
              <user-add-information
                v-if="localUser.info"
                :information-data="localUser.info"
              />
            </validation-observer>
          </b-tab>

          <!-- social links -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="LinkIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('Social') }}</span>
            </template>

            <validation-observer
              ref="refSocialObserver"
            >
              <user-add-social
                v-if="localUser.social"
                :social-data="localUser.social"
              />
            </validation-observer>
          </b-tab>

          <!-- notification -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="BellIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('Notifications') }}</span>
            </template>

            <validation-observer
              ref="refSocialObserver"
            >
              <user-add-notification
                v-if="localUser.notification"
                :notification-data="localUser.notification"
              />
            </validation-observer>
          </b-tab>

        </b-tabs>

        <b-card
          class="ml-1 mr-1"
          style="margin-top: -26px !important;"
        >
          <b-row style="margin-top: -20px !important;">
            <b-col cols="8">
              <b-button
                v-if="tabAdd !== 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="mt-2 mr-1"
                @click="onBack"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="12"
                />
                <span class="ml-25 align-middle">{{ $t('Back') }}</span>
              </b-button>
              <b-button
                ref="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="onSubmit"
              >
                <span
                  class="ml-25 align-middle"
                >{{ (tabAdd === 5)? $t('Save Add') : $t('Next') }}</span>
                <feather-icon
                  v-if="tabAdd !== 5"
                  icon="ChevronRightIcon"
                  size="12"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetLocalForm"
              >
                {{ $t('Reset') }}
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="text-right d-none d-md-block d-lg-block"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                type="button"
                class="mt-2"
                @click.prevent="gotoUserList"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="12"
                />
                <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="d-lg-none d-md-none d-sm-block"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                type="button"
                class="mt-2"
                @click.prevent="gotoUserList"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="12"
                />
                <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              ref="dialogOk"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormFile,
  BTabs, BTab,
  BForm,
  BCard,
  BButton,
  BRow,
  BCol,
  BOverlay,
  BProgress,
  BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import {
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import UserAddGeneral from './UserAddGeneral.vue'
import UserAddPermission from './UserAddPermission.vue'
import UserAddPassword from './UserAddPassword.vue'
import UserAddInformation from './UserAddInformation.vue'
import UserAddSocial from './UserAddSocial.vue'
import UserAddNotification from './UserAddNotification.vue'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormFile,
    BTabs,
    BTab,
    BForm,
    BCard,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BProgress,
    BCardText,

    UserAddGeneral,
    UserAddPermission,
    UserAddPassword,
    UserAddInformation,
    UserAddSocial,
    UserAddNotification,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // options: {},
      validateData: {
        general: false,
        password: false,
      },
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      imageFile: null,
    }
  },
  mounted() {
    this.getDefaultUser()
  },
  methods: {
    getDefaultUser() {
      store
        .dispatch('user-store/getDefaultUser', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.localUser = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    resetImage() {
      this.$refs.previewEl.src = ''
      this.imageFile = null
    },
    resetLocalForm() {
      this.$refs.refFormObserver.reset()
      this.getDefaultUser()
      this.tabAdd = 0
    },
    onBack() {
      this.tabAdd -= 1
    },
    onSubmit() {
      if (this.tabAdd === 0) {
        this.$refs.refGeneralObserver.validate().then(success => {
          if (success) {
            this.tabAdd = 1
          } else {
            this.tabAdd = 0
          }
        })
      } else if (this.tabAdd === 1) {
        this.$refs.refPermissionObserver.validate().then(success => {
          if (success) {
            this.tabAdd = 2
          } else {
            this.tabAdd = 1
          }
        })
      } else if (this.tabAdd === 2) {
        this.$refs.refPasswordObserver.validate().then(success => {
          if (success) {
            this.tabAdd = 3
          } else {
            this.tabAdd = 2
          }
        })
      } else if (this.tabAdd === 3) {
        this.$refs.refInformationObserver.validate().then(success => {
          if (success) {
            this.tabAdd = 4
          } else {
            this.tabAdd = 3
          }
        })
      } else if (this.tabAdd === 4) {
        this.$refs.refSocialObserver.validate().then(success => {
          if (success) {
            this.tabAdd = 5
          } else {
            this.tabAdd = 4
          }
        })
      } else if (this.tabAdd === 5) {
        this.$refs.refSocialObserver.validate().then(success => {
          if (success) {
            this.$refs.refFormObserver.validate().then(formSuccess => {
              if (formSuccess) {
                // ok
                this.processing = false
                this.busy = true
              } else {
                // not complete
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'please complete required',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })

                this.tabAdd = 0
              }
            })
          }
        })
      }
    },
    gotoUserList() {
      this.$router.push({ name: 'user-list' })
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      store.dispatch('user-store/addUser', { data: this.localUser })
        .then(response => {
          if (this.imageFile !== null) {
            this.saveImage(response.data.id)
          } else {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.$swal({
              icon: 'success',
              title: this.$i18n.t('Added'),
              text: this.$i18n.t('Your data has been added'),
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.$router.push({ name: 'user-list' })
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })

      this.$refs.submit.focus()
    },
    saveImage(id) {
      const formData = new FormData()
      formData.append('file', this.imageFile)

      store.dispatch('user-store/uploadImageUser', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.imageFile = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Added'),
            text: this.$i18n.t('Your data has been added'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$router.push({ name: 'user-list' })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })

    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const localUser = ref({})

    const tabAdd = 0
    const tabIndex = 0

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,

      localUser,
      tabAdd,
      tabIndex,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
