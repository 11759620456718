<template>
  <b-card>
    <b-row>
      <!-- PERMISSION TABLE -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('Permission') }}</span>
            </b-card-title>
          </b-card-header>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="permissionData.permissions"
            :fields="permissionsTableColumns"
          >
            <template #cell(module)="data">
              {{ $t(data.value) }}
            </template>

            <template #cell(read)="data">
              <b-form-checkbox
                v-model="data.item.read"
                switch
              />
            </template>

            <template #cell(write)="data">
              <b-form-checkbox
                v-model="data.item.write"
                switch
              />
            </template>

            <template #cell(create)="data">
              <b-form-checkbox
                v-model="data.item.create"
                switch
              />
            </template>

            <template #cell(delete)="data">
              <b-form-checkbox
                v-model="data.item.delete"
                switch
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
} from '@vue/composition-api'
import { required } from '@validations'
import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    permissionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('adx-user-data')),
      imageFile: null,
      required,
    }
  },
  setup() {
    const permissionsTableColumns = [
      { key: 'module', label: t('module') },
      { key: 'read', label: t('read') },
      { key: 'write', label: t('write') },
      { key: 'create', label: t('create') },
      { key: 'delete', label: t('delete') },
    ]

    return {
      permissionsTableColumns,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
